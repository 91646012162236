import { useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import CategoryItem from './serviceCategoryItem';
import useTranslateGetLanguage from 'hooks/useTranslateGetLanguage';
import { useSelector } from 'store';

const serviceCategoriesStart = [
    'MEMBERSHIPS',
    'HAIR CARE AND STYLE',
    'SHAVING - BEARD CARE',
    'LOUNGE',
    'STYLE CONSULTATION',
    'ALL SERVICE CATEGORIES',
];

const serviceCategoriesItalian = [
    'Memberships',
    'Cura e Stile dei Capelli',
    'Rasatura - Cura della Barba',
    'Lounge',
    'Consulenza di Stile',
    'Tutti i servizi',
];

const ServiceCategories = () => {
    const lang = useTranslateGetLanguage();
    const [categories, setCategories] = useState(serviceCategoriesStart);
    const { apptCategories } = useSelector((state) => state.appointments);

    useEffect(() => {
        if (apptCategories.length > 0) {
            setCategories(apptCategories);
            return;
        }
        if (lang === 'en') setCategories(serviceCategoriesStart);
        else if (lang === 'it') setCategories(serviceCategoriesItalian);
        else setCategories(serviceCategoriesStart);
    }, [lang, apptCategories]);
    return (
        <Grid
            container
            border={0}
            id="container-holding-categories"
            justifyContent={window.isTiny ? 'center' : 'space-between'}
            sx={{ px: window.isTiny ? 0 : 9 }}>
            {categories.map((cat, i) => {
                return (
                    <CategoryItem
                        key={i}
                        idtouse={`category-dibs-service-${i}`}
                        step={i}
                        categoryName={cat}
                    />
                );
            })}
        </Grid>
    );
};
export default ServiceCategories;
