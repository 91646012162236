import axios from 'axios';

export default async function createNewDibsUser(thisdata) {
    const baseURL = process.env.REACT_APP_BASE_URL;
    const basicurl = `${baseURL}/create-new-dibs-user`;
    let datatosend = {};
    const createUser = async () => {
        await axios
            .post(basicurl, {
                thisdata,
            })
            .then((res) => {
                const { data } = res;
                datatosend = data;
            })
            .catch((err) => {
                console.log(`error creating new dibs user: ${err}`);
                return err;
            });
    };
    await createUser();
    return datatosend;
}
