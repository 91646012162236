import * as React from 'react';
import Box from '@mui/material/Box';
import { Modal, Backdrop, Fade, IconButton, Grid } from '@mui/material';

import { useSelector, dispatch } from 'store';
import { setWidgetWidth } from 'store/slices/dibsstudio';
import CloseIcon from '@mui/icons-material/Close';
import AccountHeader from 'layouts/header-new';
import UpdateConfigs from 'views/wrappers/updateConfigs';

import BookNowButton from 'assets/icons/bookNowButton';
// import { dispatch } from 'store';
import { setWidgetIsOpen } from 'store/slices/menu';

const getWidth = (wdt) => {
    // const wdt = '100vw';
    switch (true) {
        case wdt < 600:
            return parseInt(wdt * 0.96);
        case wdt < 960:
            return 660;
        case wdt < 1280:
            return 960;
        default:
            return parseInt(wdt * 0.8);
    }
};
const getHeight = (ht, wdt) => {
    let isPortrait = true;
    let isShort = ht <= 900 ? true : false;
    if (ht > wdt) {
        isPortrait = true;
    } else {
        isPortrait = false;
    }
    // const isPortrait = window.screen.orientation.type.includes('portrait');
    switch (true) {
        case isPortrait:
            return parseInt(ht * 0.96);
        case isShort:
            return ht - 100;
        default:
            return ht - 150;
    }
};
const styleStarter = {
    p: window.innerWidth < 500 ? 1 : 4,
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    overflowX: 'hidden',
    overflowY: 'auto',
    bgcolor: 'background.paper',
    border: '0px solid #000',
    borderColor: 'primary.main',
    borderRadius: 2,
    boxShadow: 24,
    pt: 3,
    scrollbarGutter: 'stable both-edges',
    '&::-webkit-scrollbar': {
        width: '0.7em',
        // display: 'none',
    },
    '&::-webkit-scrollbar-track': {
        boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
        borderRadius: 5,
    },
    '&::-webkit-scrollbar-thumb': {
        // backgroundColor: 'rgba(0,0,0,.1)',
        backgroundColor: 'transparent',
        height: '50%',
        // outline: '1px solid slategrey',
    },

    '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
        borderRadius: 5,
        backgroundColor: '#eee',
    },
};

export default function LoadingModalAsFullPage({ children }) {
    console.log('\n\n\nTEST 4 - widget content page');

    const [open, setOpen] = React.useState(false);
    const [widthToUse, setWidthToUse] = React.useState(0);
    const [heightToUse, setHeightToUse] = React.useState(0);
    const [widthPassedIn, setWidthPassedIn] = React.useState(1000);
    const [heightPassedIn, setHeightPassedIn] = React.useState(600);
    const [style, setStyle] = React.useState(styleStarter);
    const [widgetOpenedFromParent, setWidgetOpenedFromParent] =
        React.useState(false);

    window.addEventListener('message', (event) => {
        try {
            const { widgetopenedfromparent } = event.data;
            // console.log('widgetopenedfromparent is: ', widgetopenedfromparent);
            if (widgetopenedfromparent) {
                setWidgetOpenedFromParent(true);
            }
        } catch (error) {
            console.log('error from iframe event listener is: ', error);
        }
        try {
            const { windowWidth, windowHeight } = event.data;
            if (windowWidth > 0 || windowHeight > 0) {
                if (windowWidth > 0) {
                    setWidthPassedIn(windowWidth);
                }
                if (windowHeight > 0) {
                    setHeightPassedIn(windowHeight);
                }
            }
        } catch (error) {
            console.log('error from iframe event listener is v2: ', error);
        }
    });

    const handleClose = () => {
        setOpen(false);
        window.parent.postMessage(
            {
                widgetopen: false,
            },
            '*',
        );
        dispatch(setWidgetIsOpen(false));
        setWidgetOpenedFromParent(false);
    };
    React.useEffect(() => {
        const getStyle = (color) => {
            const styleupdate = {
                ...styleStarter,
                '&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb': {
                    borderRadius: 5,
                    backgroundColor: color,
                    // minHeight: 5,
                    // border: '1px solid rgb(242,172,150)',
                },
            };
            setStyle(styleupdate);
        };
        getStyle('primary.main');

        if (window.innerWidth > 10) {
            setWidthPassedIn(window.innerWidth);
        }
        if (window.innerHeight > 10) {
            setHeightPassedIn(window.innerHeight);
        }
        const heightOfView = getHeight(heightPassedIn, widthPassedIn);
        setHeightToUse(heightOfView);
        const widthOfView = getWidth(widthPassedIn);
        setWidthToUse(widthOfView);
        // console.log(
        //     'setting width widget on displayasmodalfullpage',
        //     widthOfView,
        // );
        // dispatch(setWidgetWidth(widthOfView));
    }, [heightPassedIn, open, widthPassedIn]);

    React.useEffect(() => {
        if (widgetOpenedFromParent) {
            setOpen(true);
            dispatch(setWidgetIsOpen(true));
        }
    }, [widgetOpenedFromParent]);

    const openDrawerFromButton = () => {
        setOpen(true);
        dispatch(setWidgetIsOpen(true));
    };
    const ModalFullPageHeader = () => {
        return (
            <Grid
                container
                id="modal-full-page-header"
                justifyContent="space-between"
                alignItems="flex-start"
                border={0}
                // sx={{ mt: buttonControlsWidget ? 1 : 0 }}
            >
                <Grid item xs={2} sx={{ ml: 0, pl: 0 }} border={0}>
                    <IconButton
                        onClick={() => handleClose()}
                        edge="start"
                        border={1}
                        sx={{
                            justifyContent: 'flex-start',
                            ml: 0.25,
                        }}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
                <Grid item xs={10} border={0}>
                    <AccountHeader />
                </Grid>
            </Grid>
        );
    };
    const widgetContent = () => (
        <Box
            sx={style}
            id="widget-content-box"
            width={widthToUse}
            height={heightToUse}>
            <UpdateConfigs>
                <ModalFullPageHeader />
                {children}
            </UpdateConfigs>
        </Box>
    );
    return (
        <div id="div-surrounding-modal-full-page">
            <React.Fragment key={'modal-button'}>
                {/* <Button onClick={handleOpen}>Open modal</Button> */}
                {!open && !window.isIframe && (
                    <BookNowButton
                        buttonShouldSetOpen={true}
                        buttonaction={openDrawerFromButton()}
                    />
                )}
                <Modal
                    open={open}
                    onClose={handleClose}
                    closeAfterTransition
                    slots={{ backdrop: Backdrop }}
                    slotProps={{
                        backdrop: {
                            timeout: 1000,
                        },
                    }}>
                    <Fade in={open}>{widgetContent()}</Fade>
                </Modal>
            </React.Fragment>
        </div>
    );
}
