import { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Divider } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { useSelector } from 'store';
import EventItem from './eventItem';
import { setButtonControlsWidget } from 'store/slices/dibsstudio';
import { differenceBetweenTimes } from 'helpers';

const formattedTime = (datePassed) => {
    const datep = `${datePassed}`;
    const date = new Date(datep);
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    let hours12 = hours % 12;
    if (hours12 === 0) hours12 = 12;
    const minutesFormatted = minutes < 10 ? `0${minutes}` : minutes;
    const time = `${hours12}:${minutesFormatted} ${ampm}`;
    return time;
};

const EventsList = ({ setHasClassesOnThisDay }) => {
    let { dibsId } = useParams();
    const { schedule } = useSelector((state) => state.schedule);
    const { buttonControlsWidget } = useSelector(
        (state) => state.dibsstudio.config,
    );
    const { dateSelected, maxDateForComparison } = useSelector(
        (state) => state.menu,
    );
    const [listClasses, setListClasses] = useState([]);
    const listClassesMemo = useMemo(() => listClasses, [listClasses]);
    const [noEventsText, setNoEventsText] = useState(
        "There aren't any classes scheduled for this date. Check back later!",
    );
    useEffect(() => {
        // const eventsListVar = [];
        if (dibsId === '226' || dibsId === '260') {
            setNoEventsText('No more appointments today');
        }
        if (schedule.length > 0) {
            const eventsListArray = [];

            const setScheduleForEach = () => {
                schedule.forEach((event, i) => {
                    const minutesdiff = differenceBetweenTimes(
                        event.start_date,
                        event.end_date,
                    );

                    if (
                        new Date(event.start_date) >= new Date(dateSelected) &&
                        new Date(event.start_date) <
                            new Date(maxDateForComparison)
                    ) {
                        const isFull =
                            event.spots_booked >= event.seats ? true : false;

                        const valuestoshow = {
                            id: event.eventid,
                            eventTime: formattedTime(event.start_date),
                            eventName: event.name,
                            startDate: event.start_date,
                            private: event.private,
                            freeclass: event.free_class,
                            isFull,
                            price: event.price_dibs,
                            locationName: event.location.name,
                            instructorName: `${event.instructor.firstname} ${event.instructor.lastname}`,
                            instructorPhoto: event.instructor.instructor_photo,
                            minuteslong: minutesdiff,
                            canApplyPass: event.can_apply_pass,
                            hasWaitlist: event.has_waitlist,
                            seats: event.seats,
                            spotsBooked: event.spots_booked,
                        };
                        eventsListArray.push(valuestoshow);
                    }
                });
                setListClasses(eventsListArray);
            };
            setScheduleForEach();
            setHasClassesOnThisDay(true);
        } else {
            setHasClassesOnThisDay(false);
        }
    }, [
        dateSelected,
        dibsId,
        maxDateForComparison,
        schedule,
        setHasClassesOnThisDay,
    ]);
    return (
        <AnimatePresence>
            <Grid
                container
                component={motion.div}
                initial={{ x: 0 }}
                animate={{ width: '100%' }}
                exit={{
                    x: window.innerWidth,
                    transition: { duration: 200 },
                }}>
                {listClassesMemo.length === 0 && (
                    <Grid item xs={12} sx={{ mt: 5, mb: 5, ml: 4 }}>
                        <Grid container justifyContent="center">
                            {noEventsText}
                        </Grid>
                    </Grid>
                )}

                {listClassesMemo.length > 0 && (
                    <Grid item xs={12} sx={{ mb: 5 }}>
                        {listClassesMemo.map((event, i) => {
                            let isLast = false;
                            if (i === listClassesMemo.length - 1) {
                                isLast = true;
                            }
                            return (
                                <EventItem
                                    key={`${event.id}-${i}`}
                                    event={event}
                                    isLast={isLast}
                                />
                            );
                        })}
                    </Grid>
                )}
            </Grid>
        </AnimatePresence>
    );
};
export default EventsList;
