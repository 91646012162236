import PropTypes from 'prop-types';
import { createContext, useEffect, useRef } from 'react';

// project import
import defaultConfig from 'config';
import useLocalStorage from 'hooks/useLocalStorage';

// initial state
const initialState = {
    ...defaultConfig,
    onChangeMenuType: () => {},
    onChangePresetColor: () => {},
    onChangeLocale: () => {},
    onChangeRTL: () => {},
    onChangeContainer: () => {},
    onChangeFontFamily: () => {},
    onChangeBorderRadius: () => {},
    onChangeOutlinedField: () => {},
};

// ==============================|| CONFIG CONTEXT & PROVIDER ||============================== //

const ConfigContext = createContext(initialState);

function ConfigProvider({ children }) {
    const [config, setConfig] = useLocalStorage('dibs-theme-config', {
        fontFamily: initialState.fontFamily,
        borderRadius: initialState.borderRadius,
        outlinedFilled: initialState.outlinedFilled,
        navType: initialState.navType,
        presetColor: initialState.presetColor,
        locale: initialState.locale,
        rtlLayout: initialState.rtlLayout,
    });
    const hasInitialized = useRef(false);
    useEffect(() => {
        if (hasInitialized.current) return; // Prevent re-initialization
        hasInitialized.current = true;
        const initializeLocale = () => {
            const urlParams = new URLSearchParams(window.location.search);
            const lang = urlParams.get('lang');

            const determinedLocale = lang || 'en';

            if (config.locale !== determinedLocale) {
                setConfig((prevConfig) => ({
                    ...prevConfig,
                    locale: determinedLocale,
                }));
            }
        };

        initializeLocale();
    }, [config.locale, setConfig]); // Run on mount

    const onChangeMenuType = (navType) => {
        setConfig({
            ...config,
            navType,
        });
    };

    const onChangePresetColor = (presetColor) => {
        setConfig({
            ...config,
            presetColor,
        });
    };

    const onChangeLocale = (locale) => {
        console.log('CONTEXT - changing the locale here to', locale);
        setConfig({
            ...config,
            locale,
        });
    };

    const onChangeRTL = (rtlLayout) => {
        setConfig({
            ...config,
            rtlLayout,
        });
    };

    const onChangeContainer = () => {
        setConfig({
            ...config,
            container: !config.container,
        });
    };

    const onChangeFontFamily = (fontFamily) => {
        setConfig({
            ...config,
            fontFamily,
        });
    };

    const onChangeBorderRadius = (event, newValue) => {
        setConfig({
            ...config,
            borderRadius: newValue,
        });
    };

    const onChangeOutlinedField = (outlinedFilled) => {
        setConfig({
            ...config,
            outlinedFilled,
        });
    };

    return (
        <ConfigContext.Provider
            value={{
                ...config,
                onChangeMenuType,
                onChangePresetColor,
                onChangeLocale,
                onChangeRTL,
                onChangeContainer,
                onChangeFontFamily,
                onChangeBorderRadius,
                onChangeOutlinedField,
            }}>
            {children}
        </ConfigContext.Provider>
    );
}

ConfigProvider.propTypes = {
    children: PropTypes.node,
};

export { ConfigProvider, ConfigContext };
