import PropTypes from 'prop-types';

import React from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Card,
    CardContent,
    CardHeader,
    Divider,
    Typography,
} from '@mui/material';

// ==============================|| CUSTOM SUB CARD ||============================== //

const AccountSubCard = React.forwardRef(
    (
        {
            children,
            content,
            includeSecondary,
            contentClass,
            darkTitle,
            secondary,
            sx = {},
            contentSX = {},
            title,
            dibsstudioid,
            newVariantTitle,
            ...others
        },
        ref,
    ) => {
        const theme = useTheme();
        const bordercolor =
            dibsstudioid === '226' || dibsstudioid === '260'
                ? '#B6B5B5'
                : theme.palette.common.border;
        const variantToUseForTitle = newVariantTitle ? newVariantTitle : 'h5';
        let secondarytouse = secondary;
        if (!includeSecondary) {
            secondarytouse = null;
        }
        return (
            <Card
                ref={ref}
                sx={{
                    border: '1px solid',
                    borderRadius: 2,
                    borderColor:
                        dibsstudioid === '226' || dibsstudioid === '260'
                            ? '#B6B5B5'
                            : theme.palette.common.border,
                    boxShadow: 'none',
                    // ':hover': {
                    //     boxShadow:
                    //         theme.palette.mode === 'dark'
                    //             ? '0 2px 14px 0 rgb(33 150 243 / 10%)'
                    //             : '0 2px 14px 0 rgb(32 40 45 / 8%)',
                    // },
                    ...sx,
                }}
                {...others}>
                {/* card header and action */}
                {!darkTitle && title && (
                    <CardHeader
                        variant="tiny"
                        sx={{
                            p: 1,
                            bgcolor:
                                dibsstudioid === '226' || dibsstudioid === '260'
                                    ? 'transparent'
                                    : '#CED4DA',
                        }}
                        title={
                            <Typography variant={variantToUseForTitle}>
                                {title}
                            </Typography>
                        }
                        action={window.isTiny ? secondarytouse : secondarytouse}
                    />
                )}

                {/* content & header divider */}
                {title && (
                    <Divider
                        sx={{
                            opacity: 1,
                            borderColor: bordercolor,
                        }}
                    />
                )}

                {/* card content */}
                {content && (
                    <CardContent
                        sx={{ p: 2.5, ...contentSX }}
                        className={contentClass || ''}>
                        {children}
                    </CardContent>
                )}
                {!content && children}
            </Card>
        );
    },
);

AccountSubCard.propTypes = {
    children: PropTypes.node,
    content: PropTypes.bool,
    contentClass: PropTypes.string,
    darkTitle: PropTypes.bool,
    secondary: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
        PropTypes.object,
    ]),
    sx: PropTypes.object,
    contentSX: PropTypes.object,
    title: PropTypes.oneOfType([
        PropTypes.node,
        PropTypes.string,
        PropTypes.object,
    ]),
};

AccountSubCard.defaultProps = {
    content: true,
};

export default AccountSubCard;
