import axios from 'axios';

export default async function getShopifyStatus(userid) {
    const baseURL = process.env.REACT_APP_BASE_URL;

    const basicurl = `${baseURL}/shopify/get-shopify-status`;

    let datatosend = {};
    const createUser = async () => {
        await axios
            .post(basicurl, {
                userid,
            })
            .then((res) => {
                const { data } = res;
                datatosend = data;
            })
            .catch((err) => {
                console.log(
                    `error recording shopify status for this customer: ${err}`,
                );
                return err;
            });
    };
    await createUser();
    return datatosend;
}
