import { Grid, Typography, Fade } from '@mui/material';

import { useEffect, useState } from 'react';

import { gridSpacing } from 'store/constant';

import SubCard from 'ui-component/cards/AccountSubCard';
import IndividualClasses from 'components/account/upcoming-classes/indiClass';
import { useSelector } from 'store';
import { correctTime } from 'helpers/correctForTimezone';

// ==============================|| UPCOMING CLASSES ||============================== //

const UpcomingClasses = ({ classes }) => {
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [hasSuccess, setHasSuccess] = useState(false);
    const [classesDisplay, setClassesDisplay] = useState([]);
    const [showClasses, setShowClasses] = useState(false);
    const [message, setMessage] = useState('');
    const [titleText, setTitleText] = useState('Upcoming Classes');
    const [showMessage, setShowMessage] = useState(false);
    const { config } = useSelector((state) => state.dibsstudio);
    const { dibsStudioId } = config;
    useEffect(() => {
        try {
            if (classes) {
                if (classes.length > 0) {
                    setClassesDisplay(classes);
                    setShowClasses(true);
                    setMessage('');
                } else {
                    setMessage(`You don't have any upcoming classes`);
                    setShowMessage(true);
                    setShowClasses(false);
                }
            } else {
                setMessage(`You don't have any upcoming classes`);
                setShowMessage(true);
                setShowClasses(false);
            }
        } catch (err) {
            console.log('there were no upcoming classes');
            console.log(err);
        }
    }, [classes]);
    useEffect(() => {
        if (dibsStudioId === '226' || dibsStudioId === '260') {
            setTitleText('Upcoming Appointments');
        }
    }, [dibsStudioId]);
    const determineShowClass = (startdateofclass) => {
        const nowDate = new Date();
        const startDateCorrectedTimezone = correctTime(startdateofclass);
        const startDateCorrectedTimezoneFormatted = new Date(
            startDateCorrectedTimezone,
        );
        if (startDateCorrectedTimezoneFormatted > nowDate) {
            return true;
        }
        return false;
    };
    return (
        <Fade in timeout={700}>
            <Grid
                item
                xs={window.isTiny ? 12 : 11}
                sx={{ mb: 2, mr: window.isTiny ? 0 : 3 }}>
                <SubCard title={titleText} dibsstudioid={dibsStudioId}>
                    <Grid container spacing={gridSpacing}>
                        {showMessage && (
                            <Grid item xs={12}>
                                {message}
                            </Grid>
                        )}
                        {hasError && (
                            <Grid item xs={12}>
                                <Typography variant="errorText">
                                    {errorMessage}
                                </Typography>
                            </Grid>
                        )}
                        {hasSuccess && (
                            <Grid item xs={12}>
                                <Typography variant="successText">
                                    {successMessage}
                                </Typography>
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <Grid container direction="row">
                                {showClasses &&
                                    classesDisplay.map((cl, i) => {
                                        let isLast = false;
                                        if (i === classesDisplay.length - 1) {
                                            isLast = true;
                                        }
                                        const shouldshow = determineShowClass(
                                            cl.start_date,
                                        );

                                        if (shouldshow) {
                                            return (
                                                <Grid
                                                    key={i}
                                                    item
                                                    xs={12}
                                                    sx={{
                                                        mb: 2,
                                                        mr: window.isTiny
                                                            ? 0
                                                            : 4,
                                                        mt: window.isTiny
                                                            ? 0
                                                            : 2,
                                                    }}>
                                                    <IndividualClasses
                                                        key={i}
                                                        thisclass={cl}
                                                        isLast={isLast}
                                                        previous={false}
                                                    />
                                                </Grid>
                                            );
                                        }
                                        return null;
                                    })}
                            </Grid>
                        </Grid>
                    </Grid>
                </SubCard>
            </Grid>
        </Fade>
    );
};
export default UpcomingClasses;
