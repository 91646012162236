import { Grid, Box } from '@mui/material';
import { useParams } from 'react-router-dom';

import { useSelector } from 'store';

import ListOfAppts from './apptList';
import configs from 'helpers/specialConfigs';
import { correctTime } from 'helpers/correctForTimezone';
//import AddToCalendar from './../checkout/addtocalendarnew';

const ApptList = () => {
    let { dibsId } = useParams();
    const { config } = useSelector((state) => state.dibsstudio);
    const { timeZone } = config;
    const { buttonControlsWidget, dibsStudioId } = config;
    const { email, firstname, lastname, userid } = useSelector(
        (state) => state.thisuser,
    );
    const specialborder = configs(dibsStudioId, 'border');
    let bordertouse = buttonControlsWidget ? 0 : 1;
    if (specialborder !== undefined) {
        bordertouse = specialborder;
    }
    // correctTime();
    const appt = {
        title: 'Dibs Appointment Test',
        location: '327 West Broadway, New York, NY 10012',
        startTime: '2023-05-20T15:00:00.000Z',
        endTime: '2023-05-20T15:30:00.000Z',
        description: 'Best Appointment Ever',
        doNotLoad: dibsId === '226' || dibsId === '260' ? false : true,
        user: {
            email,
            firstname,
            lastname,
            userid,
        },
        timeZone,
    };

    return (
        <Box
            id="appointment-box"
            sx={{
                border: bordertouse,
                borderRadius: 2,
                borderColor: '#d3d3d3',
                px: buttonControlsWidget ? 1 : 1,
                pt: buttonControlsWidget ? 0 : 2,
                minHeight: 25,
                mt: 0,
            }}>
            <Grid container id="appt-container">
                {/* {dibsId === '226' && (
                    <AddToCalendar appt={appt} tz="America/New_York" />
                )} */}
                <Grid item xs={12} id="item-list-of-appts">
                    <ListOfAppts />
                </Grid>
            </Grid>
        </Box>
    );
};
export default ApptList;
