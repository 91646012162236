const timezones = [
    {
        value: 'America/New_York',
        text: 'Eastern Standard Time',
    },
    {
        value: 'America/Chicago',
        text: 'Central Standard Time',
    },
    {
        value: 'America/Denver',
        text: 'Mountain Standard Time',
    },
    {
        value: 'America/Los_Angeles',
        text: 'Pacific Standard Time',
    },
    {
        value: 'Europe/Rome',
        text: 'Central European Time',
    },
];
const shorttimezones = [
    {
        value: 'America/New_York',
        text: 'EST',
    },
    {
        value: 'America/Chicago',
        text: 'CST',
    },
    {
        value: 'America/Denver',
        text: 'MST',
    },
    {
        value: 'America/Los_Angeles',
        text: 'PST',
    },
    {
        value: 'Europe/Rome',
        text: 'CET',
    },
];
const daysofweek = [
    {
        value: 0,
        text: 'Sunday',
    },
    {
        value: 1,
        text: 'Monday',
    },
    {
        value: 2,
        text: 'Tuesday',
    },
    {
        value: 3,
        text: 'Wednesday',
    },
    {
        value: 4,
        text: 'Thursday',
    },
    {
        value: 5,
        text: 'Friday',
    },
    {
        value: 6,
        text: 'Saturday',
    },
];
const monthinfo = [
    {
        value: 0,
        text: 'January',
    },
    {
        value: 1,
        text: 'February',
    },
    {
        value: 2,
        text: 'March',
    },
    {
        value: 3,

        text: 'April',
    },
    {
        value: 4,

        text: 'May',
    },
    {
        value: 5,

        text: 'June',
    },
    {
        value: 6,

        text: 'July',
    },
    {
        value: 7,

        text: 'August',
    },
    {
        value: 8,

        text: 'September',
    },
    {
        value: 9,

        text: 'October',
    },

    {
        value: 10,

        text: 'November',
    },

    {
        value: 11,

        text: 'December',
    },
];

export const getTimezoneText = (tz) => {
    return timezones.find((t) => t.value === tz).text;
};
export const getShortTimezoneText = (tz) => {
    return shorttimezones.find((t) => t.value === tz).text;
};

export const getJustDate = (datetimepassed) => {
    const datethis = new Date(datetimepassed);
    const month = datethis.getUTCMonth() + 1;
    return `${datethis.getUTCFullYear()}-${month}-${datethis.getUTCDate()}`;
};
export const getJustTime = (datetimepassed) => {
    const datethis = new Date(datetimepassed);
    const hrs = datethis.getUTCHours();
    const mins = datethis.getUTCMinutes();
    let minsstring = mins < 10 ? `0${mins}` : `${mins}`;
    minsstring = mins === 0 ? `00` : `${mins}`;
    return `${hrs}:${minsstring}`;
};

export const getDateAsString = (datetimepassed, language = 'en') => {
    const datethis = new Date(datetimepassed);
    const day = datethis.getUTCDay();
    const month = datethis.getUTCMonth();
    const date = datethis.getUTCDate();
    const year = datethis.getUTCFullYear();

    const daysOfWeekIt = [
        'Domenica',
        'Lunedì',
        'Martedì',
        'Mercoledì',
        'Giovedì',
        'Venerdì',
        'Sabato',
    ];
    const monthsIt = [
        'Gennaio',
        'Febbraio',
        'Marzo',
        'Aprile',
        'Maggio',
        'Giugno',
        'Luglio',
        'Agosto',
        'Settembre',
        'Ottobre',
        'Novembre',
        'Dicembre',
    ];
    let daytext, monthtext;
    if (language === 'it') {
        daytext = daysOfWeekIt[day];
        monthtext = monthsIt[month];
        // Italian date format: "Giorno della settimana, dd Mese yyyy"
        return `${daytext}, ${date} ${monthtext} ${year}`;
    } else {
        // Assuming daysofweek and monthinfo are defined elsewhere for English
        daytext = daysofweek.find((d) => d.value === day).text;
        monthtext = monthinfo.find((m) => m.value === month).text;
        // Default (English) format: "Day, Month dd, yyyy"
        return `${daytext}, ${monthtext} ${date}, ${year}`;
    }

    // const daytext = daysofweek.find((d) => d.value === day).text;
    // const monthtext = monthinfo.find((m) => m.value === month).text;
    // return `${daytext}, ${monthtext} ${datethis.getUTCDate()}, ${datethis.getUTCFullYear()}`;
};

export const gettimeasstring = (datepassedin, language = 'en') => {
    const datethis = new Date(datepassedin);
    const hrs = datethis.getUTCHours();
    const mins = datethis.getUTCMinutes();
    let minsstring = mins < 10 ? `0${mins}` : `${mins}`;
    if (language === 'it') {
        // Italian format: 24-hour without AM/PM
        const hrsstring = hrs < 10 ? `0${hrs}` : `${hrs}`;
        return `${hrsstring}:${minsstring}`;
    } else {
        // Default format: 12-hour with AM/PM
        const ampm = hrs >= 12 ? 'pm' : 'am';
        const hrs12 = hrs > 12 ? hrs - 12 : hrs === 0 ? 12 : hrs; // Adjust for midnight and noon
        return `${hrs12}:${minsstring} ${ampm}`;
    }
};
