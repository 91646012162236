import { useLocation, useNavigate, useParams } from 'react-router-dom';

// material-ui
import { styled } from '@mui/material/styles';
import { Button, Card, CardContent, Grid, Typography } from '@mui/material';

// project imports
import AnimateButton from 'ui-component/extended/AnimateButton';
import { gridSpacing } from 'store/constant';

// styles

const ErrorWrapper = styled('div')({
    maxWidth: 750,
    margin: '1 auto',
    textAlign: 'center',
});

const ErrorCard = styled(Card)({
    minHeight: '75vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    disableElevation: true,
    boxShadow: 'none',
});

// ==============================|| ERROR PAGE ||============================== //

const Error = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const { dibsId } = useParams();
    let placetogo =
        dibsId === '226' || dibsId === '260' ? 'appointments' : 'schedule';
    console.log('\n\n\n\nerror page has been triggered');
    // originally, we sent user to login page. Not sure that makes sense on the widget.
    const handleReturnToLogin = () => {
        console.log('return to login');
        window.localStorage.removeItem('apptBeingConfirmed');
        window.localStorage.removeItem('persist:studioemployeeuser');
        window.localStorage.removeItem('persist:thisuser');
        window.localStorage.removeItem('dibsstudio-dibsstudio');
        window.localStorage.removeItem('dibsdashboard-dibsdashboard');
        window.localStorage.removeItem('datatables-datatables');
        const dibsIdToUse = window.localStorage.getItem('dibsId');

        if (dibsIdToUse) {
            navigate(`/${placetogo}/${dibsIdToUse}${location.search}`);
        } else {
            navigate(`/${placetogo}/${dibsId}${location.search}`);
        }
    };

    return (
        <ErrorCard>
            <CardContent>
                <Grid container justifyContent="center" spacing={gridSpacing}>
                    <Grid item xs={11}>
                        <ErrorWrapper>
                            <Grid
                                container
                                spacing={gridSpacing}
                                justifyContent="center">
                                <Grid item xs={12}>
                                    <Typography variant="h2">
                                        Something went wrong
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography>
                                        There was an error. Please try
                                        refreshing the page, or click below to
                                        return to the {placetogo} page.{' '}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant="body2">
                                        Click below to reset the page
                                        {placetogo === 'login'
                                            ? ' and login in again.'
                                            : '.'}
                                    </Typography>
                                </Grid>

                                <Grid item xs={12} sx={{ mt: 1 }}>
                                    <AnimateButton>
                                        <Button
                                            variant="contained"
                                            size="large"
                                            sx={{
                                                bgcolor: '#7e92a3',
                                                ':hover': {
                                                    bgcolor: '#7e92a3',
                                                },
                                            }}
                                            onClick={handleReturnToLogin}>
                                            Reset the page and return to{' '}
                                            {placetogo} page.
                                        </Button>
                                    </AnimateButton>
                                </Grid>
                            </Grid>
                        </ErrorWrapper>
                    </Grid>
                </Grid>
            </CardContent>
        </ErrorCard>
    );
};

export default Error;
