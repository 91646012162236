import { useState, useEffect } from 'react';
import { Grid, Divider } from '@mui/material';

import { useSelector, dispatch } from 'store';

import PackagesIcon from 'assets/icons/packagesIcon';
import CreditIcon from 'assets/icons/creditIcon';
import UserIcon from 'assets/icons/userIcon';
import ScheduleIcon from 'assets/icons/scheduleIcon';
import ScheduleIconAlternate from 'assets/icons/scheduleIconAlternate';
import AppointmentIcon from 'assets/icons/appointmentsIcon';
import { setShowAppts } from 'store/slices/dibsstudio';

const AccountCartCredit = () => {
    const { showingSchedule, showingAccount, showingAppointments } =
        useSelector((state) => state.menu);
    const { studioConfig, config } = useSelector((state) => state.dibsstudio);
    const { dibsStudioId } = config;
    const { color } = studioConfig;
    const { showAppointments, showSchedule } = config;
    const { appointmentTypes } = useSelector((state) => state.appointments);

    const [showPackagesAndCredit, setShowPackagesAndCredit] = useState(false);
    useEffect(() => {
        if (dibsStudioId === '226' || dibsStudioId === '260') {
            setShowPackagesAndCredit(false);
        } else {
            setShowPackagesAndCredit(true);
        }
    }, [dibsStudioId]);

    return (
        <Grid
            container
            // className="fa-icon-styles"
            direction="row"
            border={0}
            justifyContent="flex-end"
            alignItems="center"
            spacing={1.2}
            sx={{ pr: 1, pt: 1 }}>
            {showAppointments && (
                <Grid
                    item
                    sx={{ pr: showPackagesAndCredit ? 0.5 : 1, mb: 0.75 }}>
                    <AppointmentIcon on={showingAppointments} color={color} />
                </Grid>
            )}
            {showSchedule && (
                <Grid
                    item
                    sx={{ pr: showPackagesAndCredit ? 0.5 : 1, mb: 0.75 }}
                    id="item-holding-schedule-icon">
                    <ScheduleIconAlternate on={showingSchedule} color={color} />
                </Grid>
            )}
            {!showPackagesAndCredit && (
                <Divider orientation="vertical" variant="fullWidth" flexItem />
            )}
            <Grid item sx={{ pr: 1, mb: 0.75 }}>
                <UserIcon on={showingAccount} color={color} />
            </Grid>
            {showPackagesAndCredit && (
                <Divider orientation="vertical" variant="fullWidth" flexItem />
            )}
            {showPackagesAndCredit && (
                <Grid item sx={{ mr: 0.5, mb: 0.75 }}>
                    <PackagesIcon />
                </Grid>
            )}
            {/* {showPackagesAndCredit && (
                <Grid item sx={{ mr: 0.5, mb: 0.75 }}>
                    <CreditIcon />
                </Grid>
            )} */}
        </Grid>
    );
};
export default AccountCartCredit;
