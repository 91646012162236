import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';

import { useSelector } from 'store';
import ApptItem from './appointmentItem';
import ApptMultiConfirmation from './appointmentMultiConfirmation';
import ServiceCategories from './categories/serviceCategories';
import useTranslate from 'hooks/useTranslate';

const formattedTime = (datePassed) => {
    const datep = `${datePassed}`;
    const date = new Date(datep);
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    let hours12 = hours % 12;
    if (hours12 === 0) hours12 = 12;
    const minutesFormatted = minutes < 10 ? `0${minutes}` : minutes;
    const time = `${hours12}:${minutesFormatted} ${ampm}`;
    return time;
};

const ApptsList = () => {
    let { dibsId } = useParams();
    const translate = useTranslate();
    const { appointmentCategoryShowing } = useSelector((state) => state.menu);

    const [apptsBeingConfirmedMulti, setApptsBeingConfirmedMulti] = useState(
        [],
    );
    const { appointmentTypes, selectedIdsMulti } = useSelector(
        (state) => state.appointments,
    );

    const [apptsToPass, setApptstoPass] = useState(appointmentTypes);

    const [noApptsText, setNoApptsText] = useState(
        "There aren't any appointments available at this location. Check back later!",
    );

    useEffect(() => {
        const getappts = async () => {
            const thisappNotSelected = await appointmentTypes.filter((appt) => {
                return !selectedIdsMulti.includes(appt.id);
            });
            const selectedApps = await appointmentTypes.filter((appt) => {
                return selectedIdsMulti.includes(appt.id);
            });
            setApptstoPass(thisappNotSelected);

            setApptsBeingConfirmedMulti(selectedApps);
        };
        getappts();
    }, [appointmentTypes, selectedIdsMulti]);
    let topMarginToUse = 2;
    if (window.isTiny) {
        topMarginToUse = 1;
    }
    if (dibsId === '226' || dibsId === '260') {
        topMarginToUse = 0;
    }

    const thisTranslation = translate(
        'select-one-or-more-services',
        'Select One or More Services',
    );

    return (
        <AnimatePresence>
            <Grid
                container
                component={motion.div}
                initial={{ x: 0 }}
                animate={{ width: '99%' }}
                exit={{
                    x: window.innerWidth,
                    transition: { duration: 200 },
                }}>
                {appointmentTypes.length === 0 && (
                    <Grid item xs={12} sx={{ mt: 3 }} id="no-appts-text">
                        <Grid container justifyContent="center">
                            {noApptsText}
                        </Grid>
                    </Grid>
                )}

                {appointmentTypes.length > 0 && (
                    <Grid
                        item
                        xs={12}
                        border={0}
                        id="book-a-service"
                        sx={{ mt: topMarginToUse, mb: 8 }}>
                        <Typography
                            variant={
                                dibsId === '226' || dibsId === '260'
                                    ? 'h1'
                                    : 'h2'
                            }
                            textAlign="center"
                            sx={{ mb: 3 }}>
                            {dibsId === '226' || dibsId === '260'
                                ? thisTranslation
                                : 'Book a service'}
                        </Typography>
                        {(dibsId === '226' || dibsId === '260') && (
                            <ServiceCategories />
                        )}
                        {apptsBeingConfirmedMulti.map((appt, i) => {
                            let isLast = false;
                            if (i === appointmentTypes.length - 1) {
                                isLast = true;
                            }
                            if (
                                appointmentCategoryShowing !==
                                    'ALL SERVICE CATEGORIES' &&
                                appt.service_category !==
                                    appointmentCategoryShowing
                            )
                                return null;
                            return (
                                <ApptMultiConfirmation
                                    key={appt.id}
                                    appt={appt}
                                    isLast={isLast}
                                />
                            );
                        })}
                        <ApptMultiConfirmation
                            apptList={apptsBeingConfirmedMulti}
                        />
                        {apptsToPass.map((appt, i) => {
                            let isLast = false;
                            if (i === appointmentTypes.length - 1) {
                                isLast = true;
                            }

                            const normalizeString = (str) =>
                                str.trim().replace(/–|—/g, '-').toLowerCase(); // replace en dash and em dash with hyphen

                            if (
                                normalizeString(appointmentCategoryShowing) !==
                                    'all service categories' &&
                                normalizeString(appt.service_category) !==
                                    normalizeString(appointmentCategoryShowing)
                            ) {
                                return null;
                            }

                            return (
                                <ApptItem
                                    key={appt.id}
                                    appt={appt}
                                    isLast={isLast}
                                />
                            );
                        })}
                    </Grid>
                )}
            </Grid>
        </AnimatePresence>
    );
};
export default ApptsList;
