import { Grid, Typography, Button } from '@mui/material';

import { useEffect, useState } from 'react';

import { gridSpacing } from 'store/constant';
import { useSelector } from 'store';

import SubCard from 'ui-component/cards/AccountSubCard';
import IndividualClasses from 'components/account/upcoming-classes/indiClass';
import { correctTime } from 'helpers/correctForTimezone';

// ==============================|| PAST CLASSES ||============================== //

const PastClasses = ({ classes }) => {
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [hasSuccess, setHasSuccess] = useState(false);
    const [classesDisplay, setClassesDisplay] = useState([]);
    const [showClasses, setShowPayments] = useState(true);
    const [message, setMessage] = useState('');
    const [pastmessage, setPastMessage] = useState('');
    const { config } = useSelector((state) => state.dibsstudio);
    const { dibsStudioId } = config;
    useEffect(() => {
        if (classes !== undefined) {
            if (classes.length > 0) {
                setClassesDisplay(classes);
                // setShowPayments(true);
            } else {
                setMessage(`You don't have any past classes`);
            }
        }
    }, [classes]);
    const determineShowClass = (startdateofclass) => {
        const nowDate = new Date();
        const startDateCorrectedTimezone = correctTime(startdateofclass);
        const startDateCorrectedTimezoneFormatted = new Date(
            startDateCorrectedTimezone,
        );
        if (startDateCorrectedTimezoneFormatted < nowDate) {
            return true;
        }
        return false;
    };
    return (
        <Grid
            item
            xs={window.isTiny ? 12 : 11}
            sx={{ mb: 2, mr: window.isTiny ? 0 : 3 }}>
            <SubCard
                title={
                    dibsStudioId === '226' || dibsStudioId === '260'
                        ? 'Past Appointments'
                        : 'Past Classes'
                }
                dibsstudioid={dibsStudioId}>
                <Grid container spacing={gridSpacing}>
                    {hasError && (
                        <Grid item xs={12}>
                            <Typography variant="errorText">
                                {errorMessage}
                            </Typography>
                        </Grid>
                    )}
                    {hasSuccess && (
                        <Grid item xs={12}>
                            <Typography variant="successText">
                                {successMessage}
                            </Typography>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        {message}
                    </Grid>
                    <Grid item xs={12} border={0}>
                        <Grid container direction="row">
                            {showClasses &&
                                classesDisplay.map((cl, i) => {
                                    let isLast = false;
                                    if (i === classesDisplay.length - 1) {
                                        isLast = true;
                                    }
                                    const shouldshow = determineShowClass(
                                        cl.start_date,
                                    );
                                    if (shouldshow) {
                                        return (
                                            <Grid
                                                key={i}
                                                item
                                                xs={12}
                                                sx={{
                                                    mb: 2,
                                                    mr: window.isTiny ? 0 : 4,
                                                    mt: window.isTiny ? 0 : 2,
                                                }}>
                                                <IndividualClasses
                                                    key={i}
                                                    thisclass={cl}
                                                    isLast={isLast}
                                                    previous
                                                />
                                            </Grid>
                                        );
                                    }
                                    return null;
                                })}
                        </Grid>
                    </Grid>
                </Grid>
            </SubCard>
        </Grid>
    );
};
export default PastClasses;
