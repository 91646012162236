import { useEffect, useState } from 'react';
import { useSearchParams, useParams } from 'react-router-dom';
import { Grid, Typography } from '@mui/material';
import { auth } from 'firebaseConfig';
import { useAuthState } from 'react-firebase-hooks/auth';

import { useSelector, dispatch } from 'store';
import {
    setAlreadyHasAccount,
    setIsLoggedIn,
    clearThisUser,
    setRecentlyLoggedIn,
} from 'store/slices/thisuser';

import { setFilteredTrue, setFilteredFalse } from 'store/slices/menu';

import { setShowAppts } from 'store/slices/dibsstudio';
// component imports
import AccountCartCreditSection from 'components/headers/accountCartCredit';
import BackChevronIcon from 'assets/icons/backChevron';
import ErrorAlert from 'components/error-success/ErrorAlert';

// actions
import doesUserHaveAccount from 'actions/doesUserHaveAccount';
import setUserInStore from 'actions/setThisUserStore';
import setShopifyStatusInStore from 'actions/setShopifyStatusInStore';
import {
    constructDateinUtc,
    constructDatePlusOneDayinUtc,
} from 'helpers/datehelp';

const HeaderLayout = () => {
    const [searchParams] = useSearchParams();
    const filtered = searchParams.get('filtered');
    const datetofilterto = searchParams.get('datetofilter');
    const isFiltered = filtered === 'true';
    let { dibsId } = useParams();
    const { pageSelected } = useSelector((state) => state.menu);
    const { isRegistering } = useSelector((state) => state.thisuser);
    const [user, loading] = useAuthState(auth);
    const [xsToUse, setXsToUse] = useState(12);
    const [showPackagesHeader, setShowPackagesHeader] = useState(false);
    const [headerText, setHeaderText] = useState('Buy Plans');
    const [filterDate, setFilterDate] = useState(false);

    const {
        userid,
        firstname,
        lastname,
        email,
        alreadyHasAccount,
        phone,
        addedToShopify,
    } = useSelector((state) => state.thisuser);

    const { dibsStudioId } = useSelector((state) => state.dibsstudio.config);

    useEffect(() => {
        if (window.isTiny) {
            setShowPackagesHeader(false);
        } else if (
            pageSelected !== 'schedule' &&
            pageSelected !== 'account' &&
            pageSelected !== 'appointments' &&
            pageSelected !== 'appts-classes'
        ) {
            setShowPackagesHeader(true);
        } else {
            setShowPackagesHeader(false);
        }
        if (pageSelected === 'account-passes') {
            setShowPackagesHeader(false);
        }
        if (pageSelected === 'login' || pageSelected === 'schedule') {
            setShowPackagesHeader(false);
        }

        if (
            pageSelected === 'checkout' ||
            pageSelected === 'payment' ||
            pageSelected === 'complete-purchase'
        ) {
            setHeaderText('Checkout');
        }
        if (pageSelected === 'book') {
            setHeaderText('Book');
        }
        if (pageSelected === 'packages') {
            setHeaderText('Buy Plans');
        }
    }, [showPackagesHeader, pageSelected]);
    useEffect(() => {
        if (showPackagesHeader && !window.isTiny) {
            setXsToUse(8);
        } else if (showPackagesHeader && window.isTiny) {
            setXsToUse(10);
        } else {
            setXsToUse(12);
        }
    }, [showPackagesHeader]);

    useEffect(() => {
        // populate user account in redux store
        const checkUser = async (em) => {
            await doesUserHaveAccount(em, dibsStudioId).then((res) => {
                const { hasAccount } = res;
                if (hasAccount) {
                    dispatch(setAlreadyHasAccount(true));
                    const isPisterzi =
                        dibsStudioId === 260 || dibsStudioId === 226;
                    if (!userid || !email || !firstname) {
                        setUserInStore(res);
                    } else if (!addedToShopify && isPisterzi)
                        setShopifyStatusInStore(res);
                } else {
                    dispatch(setAlreadyHasAccount(false));
                }
            });
        };

        // if user is already logged in on firebase, check to see if they have an account on dibs
        if (user && !loading) {
            dispatch(setIsLoggedIn(true));
            dispatch(setRecentlyLoggedIn(true));
            setTimeout(() => {
                dispatch(setRecentlyLoggedIn(false));
            }, 60000 * 30); // 30 mins

            if (!userid || !lastname || !email || !firstname || !phone) {
                if (user.email !== undefined) {
                    checkUser(user.email);
                    // dispatch(setAlreadyHasAccount(false));
                }
            } else if (
                alreadyHasAccount &&
                userid !== 0 &&
                userid !== undefined
            ) {
                dispatch(setIsLoggedIn(true));
            } else {
                dispatch(setAlreadyHasAccount(false));
                dispatch(setIsLoggedIn(false));
            }
        } else if (!loading && !user && !isRegistering) {
            dispatch(clearThisUser());
            window.localStorage.removeItem('emailForSignIn');
            window.localStorage.removeItem('persist:thisuser');
            window.localStorage.removeItem('dibs-token');
        }
    }, [
        userid,
        lastname,
        email,
        user,
        alreadyHasAccount,
        dibsStudioId,
        firstname,
        phone,
        loading,
        addedToShopify,
        isRegistering,
    ]);
    useEffect(() => {
        if (isFiltered && datetofilterto) {
            setFilterDate(true);
            dispatch(
                setFilteredTrue({
                    dateFilteredToPassedIn: datetofilterto,
                    typeOfFilterPassedIn: 'date',
                }),
            );
        } else {
            setFilterDate(false);
            dispatch(setFilteredFalse());
        }
    }, [dibsId, isFiltered, datetofilterto]);

    if (filterDate && pageSelected !== 'account' && pageSelected !== 'checkout')
        return null;
    return (
        <Grid container>
            <ErrorAlert />
            <Grid
                id="header-new-item-grid"
                item
                xs={12}
                border={0}
                sx={{ mt: window.isTiny ? 1.5 : 0 }}>
                <Grid container alignItems="flex-end" border={0}>
                    {showPackagesHeader && (
                        <>
                            <Grid item xs={2} sx={{ pr: 3, pl: 0.5 }}>
                                <BackChevronIcon />
                            </Grid>
                            {!window.isTiny && (
                                <Grid item xs>
                                    <Typography
                                        variant="headers"
                                        sx={{ mt: 0, pt: 0 }}>
                                        {headerText}
                                    </Typography>
                                </Grid>
                            )}

                            {/* <Grid item xs={2}>
                            <BackChevronIcon />
                        </Grid> */}
                        </>
                    )}
                    {/* {!showPackagesHeader && (
                    <Grid item xs={8}>
                        <StudioFilters />
                    </Grid>
                )} */}
                    <Grid item xs={xsToUse}>
                        <Grid
                            container
                            direction="row"
                            border={0}
                            justifyContent="flex-end"
                            alignItems="center">
                            <Grid item xs={12} sx={{ mt: 0, mr: 0.5 }}>
                                <AccountCartCreditSection />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default HeaderLayout;
