import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Box } from '@mui/material';

import { useSelector, dispatch } from 'store';

import { setWdth } from 'store/slices/dibsstudio';
import { setPageSelected } from 'store/slices/menu';
import ListOfAccountItems from './accountList';

const AccountList = () => {
    const { accountCat, dibsId } = useParams();
    const [cdwidth, setCdWidth] = useState(1000);
    const { config } = useSelector((state) => state.dibsstudio);
    const { buttonControlsWidget } = config;

    useEffect(() => {
        setCdWidth(setCdWidth);
        const windowWidth = window.innerWidth;
        const cdWidth = parseInt(windowWidth * 0.8);
        setCdWidth(cdWidth);
        // // dispatch(setWdth(cdWidth));
    }, [dibsId]);
    useEffect(() => {
        if (
            accountCat === 'appts' ||
            accountCat === 'classes' ||
            accountCat === 'appointments'
        ) {
            dispatch(setPageSelected('appts-classes'));
        }
    }, [accountCat]);
    return (
        <Box
            sx={{
                border: buttonControlsWidget ? 0 : 1,
                borderRadius: 2,
                borderColor:
                    dibsId === '226' || dibsId === '260'
                        ? '#D1CECD'
                        : '#d3d3d3',
                px: 0,
                pt: 0,
                minHeight: 100,
                mt: 0,
            }}>
            <Grid container id="container-holding-account-items">
                <Grid item xs={12} sx={{ mt: 1, width: cdwidth }}>
                    <ListOfAccountItems dibsId={dibsId} />
                </Grid>
            </Grid>
        </Box>
    );
};
export default AccountList;
